.pc-error-fallback-page {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 999;

  .pc-notFound-page-contents {
    display: inline-block;
    height: 20.125rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .title {
    font-size: 3.375rem;
    line-height: 1.15;
    color: #333;
  }
  .description {
    color: #666;
    margin-top: 2.1875rem;
    line-height: 1.7;
  }
  .button {
    margin-top: 3.4375rem;
    color: #333;
    font-size: 100%;
    text-decoration: none;
    font-family: inherit;
    display: inline-block;
    min-width: 180px;
    min-width: 11.25rem;
    padding: 19px 30px 17px;
    padding: 1.1875rem 1.875rem 1.0625rem;
    border-radius: 60px;
    border-radius: 3.75rem;
    text-align: center;
    cursor: pointer;
    border: 1px solid #ff671d;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #ff671d;
    color: #fff;
  }
}
