@font-face {
  font-family: 'SDGothicNeobTTF-bLt';
  src: url('../assets/font/SDGothicNeobTTF-bLt.woff2') format('woff2'),
    url('../assets/font/SDGothicNeobTTF-bLt.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'SDGothicNeobTTF-dMd';
  src: url('../assets/font/SDGothicNeobTTF-dMd.woff2') format('woff2'),
    url('../assets/font/SDGothicNeobTTF-dMd.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'SDGothicNeobTTF-eSm';
  src: url('../assets/font/SDGothicNeobTTF-eSm.woff2') format('woff2'),
    url('../assets/font/SDGothicNeobTTF-eSm.woff') format('woff');
  font-display: block;
}
